import React from "react";
import cn from 'classnames'
import s from './FeatureBar.module.css'

interface FeatureBarProps {
  className?: string
  title: string
  description?: string
  hide?: boolean
  action?: React.ReactNode
}

const FeatureBar = ({
  title,
  description,
  className,
  action,
  hide,
}: FeatureBarProps) => {
  const rootClassName = cn(
    s.root,
    {
      transform: true,
      'translate-y-0 opacity-100 cokkie-msg-sec': !hide,
      'translate-y-full opacity-0 hidden': hide,
    },
    className
  )
  return (
    <div className={rootClassName}>
      <span className="block md:inline text-white text-xs">{title}</span>
      <span className="block mb-6 md:inline md:mb-0 md:ml-2">
        {description}
      </span>
      {action && action}
    </div>
  )
}

export default FeatureBar
